import React, { useState, useEffect } from "react";
import { Route, Link, useNavigate } from "react-router-dom";
import config from "../../../config.json";

function HomePage() {
  return (
    <div>
      <h4>Home Page</h4>
    </div>
  );
}

export default HomePage;
