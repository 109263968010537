import React, { useEffect } from "react";
import { Route, Link, useNavigate } from "react-router-dom";
import config from "../../../config.json";
import "./downloadPage.scss";

function DownloadPage() {
  const navigate = useNavigate();
  useEffect(() => {
    const operationSystem = getMobileOperatingSystem();
    redirectTo(operationSystem);
  }, []);

  const getMobileOperatingSystem = () => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  const redirectTo = (operationSystem) => {
    switch (operationSystem) {
      case "Android":
        return (window.location.href = config.GOOGLE_PLAY);
      case "iOS":
        return (window.location.href = config.APPLE_STORE);
      case "unknown":
        return navigate("/downloadPage");
      default:
        return navigate("/");
    }
  };

  return (
    <div className="download-page">
      <section className="container">
        <div className="header">
          <img src="doneLogo.png" alt="Done logo" />
        </div>
        <div className="title">
          <p>לוקרים חכמים שעושים לכם חיים קלים</p>
        </div>
        <div className="text">
          <p> הלוקרים שלנו מותקנים ברחבי הארץ. באפליקציה תוכלו:</p>
        </div>
        <div className="list">
          <div className="list-item">
            <div className="dot"></div>
            <span>לאתר את הלוקר הקרוב</span>
          </div>
          <div className="list-item">
            <div className="dot"></div>
            <span>לעקוב אחרי סטטוס הזמנה</span>
          </div>
          <div className="list-item">
            <div className="dot"></div>
            <span>לראות את היסטוריית ההזמנות שלכם</span>
          </div>
          <div className="list-item">
            <div className="dot"></div>
            <span>לבדוק זמינות התאים בלוקרים</span>
          </div>
          <div className="list-item">
            <div className="dot"></div>
            <span>לבחור לוקר מועדף</span>
          </div>
          <div className="list-item">
            <div className="dot"></div>
            <span>להרשם ולעדכן פרטים אישיים</span>
          </div>
          <div className="list-item">
            <div className="dot"></div>
            <span>ועוד…</span>
          </div>
        </div>
        <div className="download-text">
          <p>להורדת האפליקציה לחצו כאן</p>
        </div>
        <div className="btn-wrapper">
          <a href={config.APPLE_STORE}>
            <img src="iconAppAppStore.png" alt="app store icon" />
          </a>
          <a href={config.GOOGLE_PLAY}>
            <img src="iconAppGooglePlay.png" alt="play market icon" />
          </a>
        </div>
      </section>
    </div>
  );
}

export default DownloadPage;
