import React from "react";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import HomePage from "./components/pages/homePage/homePage.js";
import DownloadPage from "./components/pages/downloadPage/downloadPage.js";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="downloadPage" element={<DownloadPage />} />
      </Routes>
    </div>
  );
}

export default App;
